import classNames from 'classnames'
import React, { useState, useEffect } from 'react'

import Button from 'components/button'
import PageHead from 'components/head/PageHead'
import PageWrapper from 'components/layout/page-wrapper'
import data from 'data/pages/Contents/share-safe-report.json'
import { openDeepLink } from 'utils'

import 'static/confirm/scss/styles.scss'

const page = 'share-safe-report'

function ShareSafeReportPage() {
  const [animate, setAnimate] = useState(false)

  useEffect(() => setTimeout(setAnimate(true), 1), [])

  return (
    <PageWrapper
      className={classNames('x__page-confirm', {
        'x__page-confirm--animating': animate,
      })}
      backgroundColor="rgb(31, 27, 52)"
      headerProps={{ isBannerHidden: true, minimal: true }}
      removeFooter
    >
      <figure className="x__icon-container x__icon-container--animating">
        <img className="x__icon--small" src="/confirm/images/safe-report--icon.png" />
      </figure>

      <div className="x__headings-container--animating">
        <div className="x__heading">
          <h1 className="x-header__heading">{data.h1.copy}</h1>
        </div>

        <div className="x__subheading">
          <h2 className="x-header__subheading">
            <span>{data.h2.copy}</span>
          </h2>
        </div>

        <div className="x__buttons-container">
          {data.buttons.map(({ deepLinkPath, ...rest }, index) => {
            return <Button key={index} {...rest} onClick={() => openDeepLink(deepLinkPath)} />
          })}
        </div>
      </div>
    </PageWrapper>
  )
}

export default ShareSafeReportPage

// <head> component:
export function Head() {
  return <PageHead page={page} noindex />
}
